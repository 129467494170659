/**
 * # Focus
 *
 * Set auto-focus-trigger on form target component after a event on a html component.
 *
 * ## HTML template
 *
 * <a data-event-focus-trigger></a>                // The event of this link triggers the focus of a input target
 * <input data-select-focus-trigger>
 *
 * ## HTML template with data attribute value
 *
 * <a data-event-focus-trigger="focus-test1"></a>  // Triggers are allowed to have unique id's.
 *                                                 // In this instance the id is: focust-test1
 * <input data-select-focus-trigger="focus-test1"> // the trigger from 'data-event-focus-trigger="focus-test1"'...
 *                                                 // allows the 'data-select-focus-trigger="focus-test1"'...
 *                                                 // to focus because both share them same id
 *
 * -------------------------------------------------------------------------------------
 *
 * <a data-event-focus-trigger></a>                // triggers are also allowed to target container...
 *                                                 // classes with child input tags.
 *                                                 // The first input tag becomes focussed.
 * <div data-select-focus-trigger>
 *  <input>
 *  <input>
 * </div>
 *
 * ----------------------------------------------------------------------------------------
 *
 * <a
 *  data-event-focus-trigger
 *  data-param-focus-trigger-state="1"             // define the action of the trigger:
 * ></a>                                           // 0: blur/unfocus the input
 *                                                 // 1: focus the input (default)
 *
 */
export default class FocusTrigger {
  /**
   * Set this attribute to a eventable element that should trigger a focus on a input element
   *
   * @type {string}
   */
  static dataEventFocusTrigger = 'data-event-focus-trigger';

  /**
   * Set this attribute to the eventable element
   * The value options are:
   *  '1' : input field is focussed when event is triggered
   *  '0' : input field is blurred when event is triggered
   *
   * If this attribute is not set, then value is always '1'.
   *
   * @type {string}
   */
  static dataParamFocusTriggerState = 'data-param-focus-trigger-state';

  /**
   * Set this attribute to the input field or the wrapper around the input field that you want to have focussed/blurred
   * after a event is triggered by the eventable element
   *
   * @type {string}
   */
  static dataSelectFocusTrigger = 'data-select-focus-trigger';

  static config = {};

  static objects = {
    events: [],
    selects: [],
  };

  /**
   * @description Constructor
   *
   * @param {string} eventType Event listener type
   * @param {string} selectType Form select type
   */
  static init({
    eventType = 'click',
    selectType = 'input',
  }) {
    this.config = {
      eventType,
      selectType,
    };
    this.objects.events = document.querySelectorAll(`[${this.dataEventFocusTrigger}]`);
    if (0 === this.objects.events.length) return;
    this.objects.selects = document.querySelectorAll(`[${this.dataSelectFocusTrigger}]`);
    this.objects.events.forEach(event => event.addEventListener(this.config.eventType, trigger => {
      const eventValue = trigger.currentTarget.getAttribute(this.dataEventFocusTrigger);
      this.objects.selects.forEach(select => {
        const selectValue = select.getAttribute(this.dataSelectFocusTrigger);
        if ('' !== selectValue && eventValue !== selectValue) return;
        const input = this.config.selectType === select.tagName
          ? select
          : select.querySelector(`${this.config.selectType}:not([aria-hidden="true"]):not([type="hidden"])`);
        if (1 === parseInt(input.getAttribute(this.dataParamFocusTriggerState) ?? 1, 10)) {
          input.focus();
        } else {
          input.blur();
        }
      });
    }, { passive: true }));
  }
}

// Get the button
const myButton = document.getElementById('btn-back-to-top');
const pxFromTop = 500;
const scrollFunction = () => {
  myButton.classList.add('hidden');
  if (pxFromTop < document.body.scrollTop || pxFromTop < document.documentElement.scrollTop) {
    myButton.classList.remove('hidden');
  }
};
const backToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

// When the user clicks on the button, scroll to the top of the document
myButton.addEventListener('click', backToTop);

window.addEventListener('scroll', scrollFunction);

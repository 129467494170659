config = {
  safelist: [
    'md:before:w-1/2',
    'md:before:w-1/3',
    'md:before:w-1/4',
    'md:before:w-1/5',
    'before:h-1/2',
    'before:h-1/3',
    'before:h-1/4',
    'before:h-1/5',
    '!text-left',
    '!text-right',
    '!text-center',
    'text-fontcolor-red',
    {
      pattern: /^gap-/,
    },
    {
      pattern: /^bg-wave/,
      variants: ['after'],
    },
  ],
  content: [
    './templates/**/*.html.twig',
    './assets/{vue,js}/**/*.{vue,js}',
  ],
  theme: {
    extend: {
      listStyleImage: {
        'arrow-right': "url('/public/cdn/img/common/arrows/angle-right-default.svg')",
      },
      spacing: {
        '0.5': '0.125rem',
        '17': '4.25rem',
        '22': '5.5rem',
        'screen': '100vw',
        'wave-height': '3vw',
        'inherit': 'inherit',
        'unset': 'unset',
      },
      transitionProperty: {
        'left': 'left',
        'height': 'height',
      },
      outlineWidth: {
        'none': 'none',
      },
      boxShadow: {
       'menu': '0 20px 25px -5px rgb(78 71 156 / 5%), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
       'modal': '0 0 0 3px rgba(200, 100, 100, 0.5)',
       'form': 'rgba(0, 0, 0, 0.2) 0 4px 12px',
      },
      textUnderlineOffset: {
        '2': '2px',
      },
      colors: {
        'whatsapp': '#25D366',
        'facebook': '#4267B2',
        'linkedin': '#0077B5',
        'twitter': '#1DA1F2',
        'extra-light-blue': '#F6F9FD',
        'light-blue-2x': '#D1E3FA',
        'light-blue': '#E8F1FC',
        'middle-light-blue': '#DCECFF',
        'blue': '#00ACDB',
        'dark-blue': '#2190ADFF',
        'blue-solid': '#E6EFFF',
        'extra-dark-blue': '#0F667C',
        '2x-dark-blue': '#0C355E',
        'baby-blue': '#B2EFFF',
        'form-border-grey': '#D8DAE2',
        'form-border-dark-grey': '#D6D6E3',
        'form-grey': '#EBEEF2',
        'form-grey-lighter': '#F3F7FC',
        'light-grey': '#f3f3f3',
        'grey': '#E5E7EB',
        'dark-grey': '#CCCCCC',
        'slate-600': '#475569',
        'light-grey-translucent': 'rgba(207,210,216,0.5)',
        'blue-translucent': 'rgba(204, 223, 255, 0.5)', // in hex: #CCDFFF
        'teal-translucent': 'rgba(19, 172, 172, 0.5)', // in hex: #13ACAC
        'azure-blue-translucent': 'rgba(0, 149, 199, 0.5)', // in hex: #0095C7
        'extra-light-red': '#F8D7DA',
        'light-red': '#DBC4C4',
        'red': '#FE705A',
        'dark-red': '#BF422F',
        'extra-dark-red': '#833426FF',
        'teal': '#13ACAC',
        'green': '#01B57B',
        'fontcolor-default': '#718096',
        'fontcolor-light-dark': '#6678A2',
        'fontcolor-dark': '#0D1C47',
        'fontcolor-blue': '#434F73',
        'fontcolor-extra-light-grey': '#C1C1C1',
        'fontcolor-light-grey': '#8D909B',
        'fontcolor-medium-grey': '#53565C',
        'fontcolor-red': '#ff6f1f',
        'fontcolor-light-blue': '#0EB0DD',
        'fontcolor-greyblue': '#4B5563',
        'bordercolor-grey': '#E8E8E8',
        'box-against-white-background': '#E7E7EC',
        'box-against-blue-background': '#DCDAE6',
        'box-against-teal-background': '#a5cfd6',
        'box-against-azure-blue-background': '#00ACDB',
        'hover-against-white-background': '#C2CBDB',
        'hover-against-blue-background': '#C2CBDB',
        'hover-against-teal-background': '#FFFFFF',
        'hover-against-azure-blue-background': '#FFFFFF',
        'highlighted-review': '#5c8d96',
        'box-on-hover': '#C2CBDB',
        'box-grey-content': '#E3E5E8',
        'rating': '#b9b7ca',
        'side-menu-background': '#F4F6F7',
        'side-menu-font': 'rgba(33,44,87,0.4)',
        'side-menu-header': '#797c8a',
        'alert-primary': '#CCE5FF',
        'alert-primary-border': '#9ECDFF',
        'alert-secondary': '#E2E3E5',
        'alert-secondary-border': '#CACBCF',
        'alert-success': '#D4EDDA',
        'alert-success-border': '#B4DFBE',
        'alert-danger': '#F8D7DA',
        'alert-danger-border': '#F1AFB5',
        'alert-warning': '#FFF3CD',
        'alert-warning-border': '#FFE79F',
        'alert-info': '#D1ECF1',
        'alert-info-border': '#AEDDE6',
        'alert-light': '#FEFEFE',
        'alert-light-border': '#E4E4E4',
        'alert-dark': '#D6D8D9',
        'alert-dark-border': '#BFC2C4',
      },
      borderWidth: {
        '1': '1px',
      },
      zIndex: {
        'min-1': '-1',
        '60': '60',
        '70': '70',
      },
      backgroundSize: {
        '100%-100%': '100% 100%',
        '100%': '100%'
      },
      backgroundImage: {
        'footer-gradient': "linear-gradient(347deg, rgba(13,28,71,1) 0%, rgba(0,159,220,1) 100%)",
        'contrast-image-text': "linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,159,220,0) 100%)",
        'contrast-image-text-reversed': "linear-gradient(0deg, rgba(0,159,220,0) 0%, rgba(0,0,0,0.7) 100%)",
        'wave-blue-solid-top-left': "url('/public/cdn/img/common/waves/blue-solid/top-left.svg?v2')",
        'wave-blue-solid-top-right': "url('/public/cdn/img/common/waves/blue-solid/top-right.svg?v2')",
        'wave-blue-solid-bottom-left': "url('/public/cdn/img/common/waves/blue-solid/bottom-left.svg?v2')",
        'wave-blue-solid-bottom-right': "url('/public/cdn/img/common/waves/blue-solid/bottom-right.svg?v2')",
        'wave-blue-translucent-top-left': "url('/public/cdn/img/common/waves/blue-translucent/top-left.svg?v2')",
        'wave-blue-translucent-top-right': "url('/public/cdn/img/common/waves/blue-translucent/top-right.svg?v2')",
        'wave-blue-translucent-bottom-left': "url('/public/cdn/img/common/waves/blue-translucent/bottom-left.svg?v2')",
        'wave-blue-translucent-bottom-right': "url('/public/cdn/img/common/waves/blue-translucent/bottom-right.svg')",
        'wave-white-solid-top-left': "url('/public/cdn/img/common/waves/white-solid/top-left.svg')",
        'wave-white-solid-top-right': "url('/public/cdn/img/common/waves/white-solid/top-right.svg')",
        'wave-white-solid-bottom-left': "url('/public/cdn/img/common/waves/white-solid/bottom-left.svg')",
        'wave-white-solid-bottom-right': "url('/public/cdn/img/common/waves/white-solid/bottom-right.svg')",
        'wave-white-translucent-top-left': "url('/public/cdn/img/common/waves/white-translucent/top-left.svg')",
        'wave-white-translucent-top-right': "url('/public/cdn/img/common/waves/white-translucent/top-right.svg')",
        'wave-white-translucent-bottom-left': "url('/public/cdn/img/common/waves/white-translucent/bottom-left.svg')",
        'wave-white-translucent-bottom-right': "url('/public/cdn/img/common/waves/white-translucent/bottom-right.svg')",
        'wave-teal-translucent-top-left': "url('/public/cdn/img/common/waves/teal-translucent/top-left.svg')",
        'wave-teal-translucent-top-right': "url('/public/cdn/img/common/waves/teal-translucent/top-right.svg')",
        'wave-teal-translucent-bottom-left': "url('/public/cdn/img/common/waves/teal-translucent/bottom-left.svg')",
        'wave-teal-translucent-bottom-right': "url('/public/cdn/img/common/waves/teal-translucent/bottom-right.svg')",
        'wave-azure-blue-translucent-top-left': "url('/public/cdn/img/common/waves/azure-blue-translucent/top-left.svg')",
        'wave-azure-blue-translucent-top-right': "url('/public/cdn/img/common/waves/azure-blue-translucent/top-right.svg')",
        'wave-azure-blue-translucent-bottom-left': "url('/public/cdn/img/common/waves/azure-blue-translucent/bottom-left.svg')",
        'wave-azure-blue-translucent-bottom-right': "url('/public/cdn/img/common/waves/azure-blue-translucent/bottom-right.svg')",
        'paper-texture': "url('/public/cdn/img/common/textures/paper/paper-200x200.jpg')",
      },
      minWidth: {
        '4xs': '5rem',
        '3xs': '10rem',
        '2xs': '15rem',
        'xs': '20rem',
        'sm': '25rem',
        'md': '30rem',
        'lg': '40rem',
        'xl': '50rem',
        '2xl': '60rem',
        '3xl': '70rem',
      },
      maxWidth: {
        'unset': 'unset',
        '3xs': '10rem',
        '2xs': '15rem',
        'xs': '20rem',
        'sm': '25rem',
        'md': '30rem',
        'lg': '40rem',
        'xl': '50rem',
        '2xl': '60rem',
        '3xl': '70rem',
      },
      minHeight: {
        '5xs': '4.5rem',
        '4xs': '5rem',
        '3xs': '10rem',
        '2xs': '15rem',
        'xs': '20rem',
        'sm': '25rem',
        'md': '30rem',
        'lg': '40rem',
        'xl': '50rem',
        '2xl': '60rem',
        '3xl': '70rem',
      },
      maxHeight: {
        '4xs': '5rem',
        '3xs': '10rem',
        '2xs': '15rem',
        'xs': '20rem',
        'sm': '25rem',
        'md': '30rem',
        'lg': '40rem',
        'xl': '50rem',
        '2xl': '60rem',
        '3xl': '70rem',
      },
    },
    screens: {
      xs: '430px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    fontFamily: {
      sans: ['Montserrat', 'Helvetica', 'Arial', 'Georgia', 'serif'],
      written: ['DejaVu Sans Mono', 'Montserrat', 'Helvetica', 'Arial', 'Georgia', 'serif']
    },
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
        sm: '2rem',
        lg: '4rem',
        xl: '5rem',
        '2xl': '6rem',
      },
    }
  },
  variants: {
    extend: {},
  },
}

module.exports = config;

import Breakpoint from '../util/breakpoint';

/**
 * # Parallax
 *
 * Create Parallax effect
 */
export default class Parallax {
  static window = window;

  /**
   * @description attribute name - (required) parallax container selector
   *
   * @type {string}
   */
  static attributeSelectParallax = 'data-select-parallax';

  /**
   * @description attribute name - (required) parallax speed factor parameter. Decides the speed of parallax movement
   * Value is decimal or integer numbers
   *
   * @type {string}
   */
  static attributeParamParallaxSpeedFactor = 'data-param-parallax-speed-factor';

  /**
   * @description attribute name - (optional) Decides at which breakpoint the parallax should work.
   * Allowed attribute values: '<sm', '<md', '<lg', '<xl', '>sm', '>md', '>lg' and '>xl'
   *
   * @type {string}
   */
  static attributeParamParallaxBreakpoint = 'data-param-parallax-breakpoint';

  /**
   * @description attribute name - (optional) set percentage when parallax of object should start from window top
   * allowed values are integers.
   *
   * @type {string}
   */
  static attributeParamParallaxStartFromTop = 'data-param-parallax-start-from-top';

  /**
   * @description attribute name - (optional) set percentage when parallax of object should start from window bottom
   * allowed values are integers.
   *
   * @type {string}
   */
  static attributeParamParallaxStartFromBottom = 'data-param-parallax-start-from-bottom';

  /**
   * @descprition Object of default parallax parameters.
   *
   * @type {{loc: {}, startFromTop: null, speedFactor: null, startFromBottom: null}}
   */
  static dataSet = {
    speedFactor: null,
    startFromTop: null,
    startFromBottom: null,
    loc: {},
  };

  /**
   * @description Container of parallax data for each element with parallax features.
   *
   * @type {array}
   */
  static dataSets = [];

  /**
   * @description Container of elements with parallax features.
   *
   * @type {array}
   */
  static elements = [];

  /**
   * @description Constructor parameters
   *
   * @type {Object}
   */
  static config = {};

  /**
   * @description Prefetch parallax data for scroll event listener
   *
   * @param {Object} constructor params - set default configuration for all parallaxes
   */
  static init({
    breakpoint = null,
    speedFactor = null,
    startFromTop = null,
    startFromBottom = null,
  }) {
    Parallax.config = {
      breakpoint,
      speedFactor,
      startFromTop,
      startFromBottom,
    };
    Parallax.setInitData();
    let resizeTimeout;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        this.elements = [];
        this.dataSets = [];
        Parallax.setInitData();
      }, 100);
    });
  }

  /**
   * @description Set initial data for parallax elements
   */
  static setInitData() {
    document.querySelectorAll(`[${this.attributeSelectParallax}]`).forEach((el, index) => {
      if (
        !Breakpoint.isActive(
          el.getAttribute(this.attributeParamParallaxBreakpoint) ?? this.config.breakpoint,
        )
      ) return;

      this.dataSets[index] = {
        ...this.dataSet,
        speedFactor: el.getAttribute(this.attributeParamParallaxSpeedFactor) ?? this.config.speedFactor,
        startFromTop: el.getAttribute(this.attributeParamParallaxStartFromTop) ?? this.config.startFromTop,
        startFromBottom: el.getAttribute(this.attributeParamParallaxStartFromBottom) ?? this.config.startFromBottom,
        loc: {
          fromTopBrowser: this.window.scrollY + el.getBoundingClientRect().top,
          clientHeight: el.clientHeight,
          windowHeight: this.window.innerHeight,
        },
      };

      this.elements[index] = el;

      Parallax.runParallaxAtInit();

      // Optional. If you wish to hide the parallax element before the translateY styling is applied,
      // than add a 'hidden', 'invisible' or 'opacity-0' class to the parallax element if you use tailwind.
      // tip: for animated visibility in tailwind add class 'transition-opacity duration-500 opacity-0'
      el.classList.remove('invisible');
      el.classList.remove('opacity-0');
      el.classList.remove('hidden');
    });

    this.window.addEventListener('scroll', () => {
      this.dataSets.forEach((dataSet, index) => {
        Parallax.runParallax(dataSet, index);
      });
    });
  }

  /**
   * @description run parallax
   *
   * @param {Object} dataSet - set of parallax data created in function 'setInitData'
   * @param {number} index   - array index from 'this.dataSets'
   */
  static runParallax(dataSet, index) {
    const scrollPos = this.window.scrollY;
    const elTop = dataSet.loc.fromTopBrowser - scrollPos;

    // only run parallax element when element is within view
    // and when element height is not larger than browser height
    if (
      dataSet.loc.clientHeight <= dataSet.loc.windowHeight
      && !(dataSet.loc.windowHeight > elTop && -Math.abs(dataSet.loc.clientHeight) < elTop)
    ) return;

    // only run parallax element when element is below XX% from window top
    if (
      null !== dataSet.startFromTop
      && 0 > (elTop - dataSet.loc.windowHeight * (dataSet.startFromTop / 100) + (dataSet.loc.clientHeight / 2))
    ) return;

    // only run parallax element when element is above XX% from window bottom
    if (
      null !== dataSet.startFromBottom
      && 0 < (elTop - dataSet.loc.windowHeight + dataSet.loc.windowHeight * (dataSet.startFromBottom / 100))
    ) return;

    const pixelShift = dataSet.loc.clientHeight <= dataSet.loc.windowHeight
      ? (elTop + (dataSet.loc.clientHeight / 2) - (dataSet.loc.windowHeight / 2)) * (dataSet.speedFactor / 100)
      : this.window.scrollY * (dataSet.speedFactor / 100);

    this.elements[index].style.transform = `translateY(${pixelShift}px)`;
  }

  /**
   * @description initialize parallax.
   */
  static runParallaxAtInit() {
    const scrollPos = this.window.scrollY;
    this.dataSets.forEach((dataSet, index) => {
      let pixelShift = 0;
      let elTop = null;
      if (
        null !== dataSet.startFromBottom
        && (dataSet.loc.windowHeight + dataSet.loc.clientHeight) < (dataSet.loc.fromTopBrowser - scrollPos)
      ) {
        // true if scroll position is above view of parallax element
        elTop = dataSet.loc.windowHeight * ((100 - dataSet.startFromBottom) / 100);
      } else if (
        null !== dataSet.startFromTop
        && 0 >= (dataSet.loc.fromTopBrowser - scrollPos)
      ) {
        // true if scroll position is below view of parallax element
        elTop = dataSet.loc.windowHeight * (dataSet.startFromBottom / 100);
      }

      if (null !== elTop) {
        pixelShift = (
          elTop + (dataSet.loc.clientHeight / 2) - (dataSet.loc.windowHeight / 2)) * (dataSet.speedFactor / 100
        );
        this.elements[index].style.transform = `translateY(${pixelShift}px)`;
      } else {
        Parallax.runParallax(dataSet, index);
      }
    });
  }
}

/**
 * @description add class to form when input element is focussed
 * and remove the same class when input element is not focussed
 */
export default class Focus {
  /**
   * @description add this attribute to the input element you want to listen too.
   * The value of this attribute is the class name that is inserted in the form when
   * the element is focussed.
   *
   * @type {string}
   */
  static dataSelectFocusListener = 'data-select-focus-listener';

  /**
   * @description configuration
   *
   * @type {{}}
   */
  static config = {};

  /**
   * @description the logic
   *
   * @param {string|null} focusClass - add class name via init if class name is not set via attribute
  */
  static init({
    focusClass = null,
  }) {
    this.config = {
      focusClass,
    };
    document.querySelectorAll(`[${this.dataSelectFocusListener}]`).forEach(input => {
      input.addEventListener('focus', event => {
        const focusClassName = event.target.getAttribute(this.dataSelectFocusListener) ?? focusClass;
        if (null === focusClassName) return;
        event.target.closest('form').classList.add(focusClassName);
      });
      input.addEventListener('blur', event => {
        const focusClassName = event.target.getAttribute(this.dataSelectFocusListener) ?? focusClass;
        if (null === focusClassName) return;
        event.target.closest('form').classList.remove(focusClassName);
      });
    });
  }
}

import translation from '../../../translations/js/typeahead.nl.yaml';

require('corejs-typeahead/dist/typeahead.jquery.min');
const Bloodhound = require('corejs-typeahead/dist/bloodhound.min');

$(window).on('load', () => {
  /**
   * Setup Bloodhound for typeahead
   */
  const typeaheadSource = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/typeahead?q=%QUERY',
      wildcard: '%QUERY',
      rateLimitWait: 100,
    },
  });

  /**
   * Enable typeahead
   */
  const $form = $('form[name="search"]');
  const $typeaheadSelector = $form.find('.typeahead');
  const $typeaheadSubmit = $form.find('button');
  const $typeaheadInput = $form.find('input[name]');
  const $cancellationId = $form.find('#search_cancellationId');
  const $companyName = $form.find('#search_companyName');

  /**
   * Keep track of last suggestion
   */
  let lastSuggestion;
  let enableSubmit = false;

  /**
   * @param suggestion
   */
  function updateSearchField(suggestion) {
    if (undefined !== suggestion) {
      $cancellationId.val(suggestion.id);
      $companyName.val(suggestion.companyName);
    }
    $form.submit();
  }

  /**
   * @description select last suggestion
   */
  function selectLastSuggestion() {
    if (
      true === enableSubmit
      && '' === $companyName.val()
      && '' === $cancellationId.val()
      && 0 < $form.find('.typeahead-search').length
    ) {
      updateSearchField(lastSuggestion);
    }
  }

  $typeaheadSelector.typeahead(
    {
      minLength: 2,
    },
    {
      display: 'companyName',
      highlight: true,
      source: typeaheadSource,
      autoselect: true,
      limit: 10,
      templates: {
        notFound: [
          `<div class="typeahead-search typeahead--not-found disable-preflight">${translation.noResults}</div>`,
        ],
        suggestion(data) {
          return `<div class="typeahead-search typeahead--suggestion">${data.companyName}</div>`;
        },
      },
    },
  ).bind('typeahead:render', (type, suggestions) => {
    if (suggestions[0]) {
      [lastSuggestion] = suggestions;
    }
  }).bind('typeahead:select', (ev, suggestion) => {
    lastSuggestion = suggestion;
    updateSearchField(suggestion);
  }).bind('typeahead:asyncrequest', (event, query, datasetName) => {
    // When google analytics is set we do a send to url with param
    if ('function' === typeof ga) {
      // eslint-disable-next-line no-undef
      ga('send', 'pageview', `/search?q=${datasetName}`);
    }
    enableSubmit = false;
  })
    .bind('typeahead:asyncreceive typeahead:asynccancel', () => {
      enableSubmit = true;
    });

  $typeaheadSubmit.on('click', () => {
    selectLastSuggestion();
  });

  $typeaheadInput.on('keypress', event => {
    if ('Enter' !== event.key) return;
    selectLastSuggestion();
  });
});

import { AdivareIsIOS } from '@adivare/js-components';

window.addEventListener('DOMContentLoaded', () => {
  // prevent a fixed/sticky element to scroll out of view when element has a focused form field.
  // This is the case for safari and chrome in ios
  function fixStickyFixedFocusIOS() {
    if (false === AdivareIsIOS()) return;
    const bodyEl = document.querySelector('body');
    const htmlEl = document.querySelector('html');
    document.querySelectorAll('.sticky input:not([type=hidden]), .fixed input:not([type=hidden])').forEach(input => {
      input.addEventListener('focus', () => {
        bodyEl.classList.add('fix-sticky-fixed-focus-ios');
        htmlEl.classList.add('fix-sticky-fixed-focus-ios');
      });
    });
  }

  // Add ARIA attributes to tables because Safari still breaks table semantics when display property is set.
  // @see https://adrianroselli.com/2018/05/functions-to-add-aria-to-tables-and-lists.html
  function addTableAria() {
    let i;
    const allTables = document.querySelectorAll('table');
    for (i = 0; i < allTables.length; i++) {
      allTables[i].setAttribute('role', 'table');
    }
    const allCaptions = document.querySelectorAll('caption');
    for (i = 0; i < allCaptions.length; i++) {
      allCaptions[i].setAttribute('role', 'caption');
    }
    const allRowGroups = document.querySelectorAll('thead, tbody, tfoot');
    for (i = 0; i < allRowGroups.length; i++) {
      allRowGroups[i].setAttribute('role', 'rowgroup');
    }
    const allRows = document.querySelectorAll('tr');
    for (i = 0; i < allRows.length; i++) {
      allRows[i].setAttribute('role', 'row');
    }
    const allCells = document.querySelectorAll('td');
    for (i = 0; i < allCells.length; i++) {
      allCells[i].setAttribute('role', 'cell');
    }
    const allHeaders = document.querySelectorAll('th');
    for (i = 0; i < allHeaders.length; i++) {
      allHeaders[i].setAttribute('role', 'columnheader');
    }
    // this accounts for scoped row headers
    const allRowHeaders = document.querySelectorAll('th[scope=row]');
    for (i = 0; i < allRowHeaders.length; i++) {
      allRowHeaders[i].setAttribute('role', 'rowheader');
    }
  }

  fixStickyFixedFocusIOS();
  addTableAria();
});

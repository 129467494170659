import { AdivareToggleClasses, AdivareToggleScroll } from '@adivare/js-components';
import FocusTrigger from './components/focusTrigger';
import FocusListener from './components/focusListener';
import Parallax from './components/parallax';

require('./components/typeahead');
require('./components/browserBugFixes');
require('./components/scrollToTopButton');

window.addEventListener('DOMContentLoaded', () => {
  AdivareToggleClasses.init();
  AdivareToggleScroll.init();
  FocusTrigger.init({});
  FocusListener.init({});
  Parallax.init({});
});

import tailwind from '../../../tailwind.config';

/**
 * Class Breakpoint
 *
 * Check if defined breakpoint is within boundary of window width
 * Allowed values: '<sm', '<md', '<lg', '<xl', '>sm', '>md', '>lg' and '>xl'
 * Values without '<' or '>' are considered to have '<'
 *
 * // Example for breakpoint detection during window resizing:
 * import Breakpoint from 'path/to/breakpoint';
 * Breakpoint.setListener('<md');
 * window.addEventListener('<md', event => {
 *   console.log('isActive', event.detail.isActive);
 *   // event.detail.isActive is only true when window width is smaller then md width
 * });
 *
 * // Example for initial breakpoint detection
 * import Breakpoint from 'path/to/breakpoint';
 * console.log('isActive', Breakpoint.isActive('<md'));
 */
export default class Breakpoint {
  /**
   * @description breakpoints
   *
   * @type {Object}
   */
  static tailwindBreakpoints = {};

  static breakpoints = {};

  static breakpointObj = {
    breakpoint: null,
    operator: null,
    bootstrap: null,
    breakAt: null,
    eventName: null,
    isActive: null,
  };

  /**
   * @description Check if breakpointRange is within boundary of window width
   *
   * @param {String|null} breakpoint - range like: '<md', '>lg' or 'none'
   *
   * @returns {boolean} true if within breakpoint range. false if not.
   */
  static isActive(breakpoint) {
    if ('undefined' === typeof breakpoint || null === breakpoint || 'none' === breakpoint) return true;
    if (0 === Object.keys(this.tailwindBreakpoints).length) this.tailwindBreakpoints = this.getTailwindBreakpoints();
    const bootstrap = breakpoint.substring(1);
    if (!(bootstrap in this.tailwindBreakpoints)) return true;
    const operator = breakpoint.charAt(0);

    const activeState = this.setActiveState(
      {
        ...Breakpoint.breakpointObj,
        breakpoint,
        operator,
        bootstrap,
        breakAt: this.tailwindBreakpoints[bootstrap],
      },
      window.innerWidth,
    );

    return null === activeState ? true : activeState;
  }

  /**
   * Import tailwind breakpoints from tailwind.config.js
   *
   * @returns {{}} tailwind breakpoints json object
   */
  static getTailwindBreakpoints() {
    if (!('screens' in tailwind.theme)) {
      throw new Error('Failed: tailwind breakpoints are not defined in tailwind.config.js!');
    }

    const tailwindBreakpoints = {};
    Object.entries(tailwind.theme.screens).forEach(([key, value]) => {
      const $split = value.split('px');
      if (1 === $split.length) throw new Error('Failed: tailwind breakpoints should be in breakAt!');
      tailwindBreakpoints[key] = parseInt($split[0], 10);
    });

    return tailwindBreakpoints;
  }

  /**
   * Set breakpoint listener.
   *
   * @param {string|null} breakpoint - range like: '<md', '>lg' or 'none'
   * @param {string|null} eventName - if eventName is not set, then 'breakpoint' parameter is set as event name
   */
  static setListener({ breakpoint, eventName = null }) {
    if ('undefined' === typeof breakpoint || null === breakpoint || 'none' === breakpoint) return;
    if (breakpoint in this.breakpoints) return;
    if (0 === Object.keys(this.tailwindBreakpoints).length) this.tailwindBreakpoints = this.getTailwindBreakpoints();
    const bootstrap = breakpoint.substring(1);
    if (!(bootstrap in this.tailwindBreakpoints)) return;
    const operator = breakpoint.charAt(0);

    Breakpoint.breakpoints[eventName ?? breakpoint] = {
      ...Breakpoint.breakpointObj,
      breakpoint,
      operator,
      bootstrap,
      eventName: eventName ?? breakpoint,
      breakAt: this.tailwindBreakpoints[bootstrap],
    };

    if (1 < Object.keys(this.breakpoints).length) return;

    let resizeTimeout;
    let windowWidth;
    window.addEventListener('resize', event => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (windowWidth === event.target.innerWidth) return;
        windowWidth = event.target.innerWidth;
        Object.keys(Breakpoint.breakpoints).forEach(key => {
          const activeState = this.setActiveState(Breakpoint.breakpoints[key], windowWidth);
          if (null === activeState) return;
          Breakpoint.breakpoints[key].isActive = activeState;
          event.target.dispatchEvent(new CustomEvent(key, {
            bubbles: true,
            detail: Breakpoint.breakpoints[key],
          }));
        });
      }, 100);
    });
  }

  /**
   * Check if breakpoint meets breakpoint condition.
   *
   * @param {Object} breakpointObj - breakpoint object as is defined in array Breakpoint.breakpoints.
   * @param {number} windowWidth - width of browser window.
   *
   * @returns {null|boolean} return condition state.
   */
  static setActiveState(breakpointObj, windowWidth) {
    if (
      (
        '>' === breakpointObj.operator
        && (false === breakpointObj.isActive || null === breakpointObj.isActive)
        && windowWidth > breakpointObj.breakAt
      )
      || (
        '<' === breakpointObj.operator
        && (false === breakpointObj.isActive || null === breakpointObj.isActive)
        && windowWidth < breakpointObj.breakAt
      )
    ) {
      return true;
    }

    if (
      (
        '>' === breakpointObj.operator
        && (true === breakpointObj.isActive || null === breakpointObj.isActive)
        && windowWidth < breakpointObj.breakAt
      )
      || (
        '<' === breakpointObj.operator
        && (true === breakpointObj.isActive || null === breakpointObj.isActive)
        && windowWidth > breakpointObj.breakAt
      )
    ) {
      return false;
    }

    return null;
  }
}
